@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=NTR&display=swap");
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shrimp {
  0% {
    height: calc(100vh + 15px);
    transform: translateY(0);
  }
  100% {
    height: 15px;
    transform: translateY(100vh);
  }
}
@keyframes bounceDown {
  0% {
    transform: translateY(-105px);
  }
  70% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-11px);
  }
}
@keyframes moveDown {
  from {
    transform: translateY(-42px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes circleSpin-1 {
  to {
    stroke-dashoffset: 875;
  }
}
@keyframes fullRotation {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes circleSpin-2 {
  0% {
    stroke-dashoffset: 875;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
* {
  font-family: Roboto;
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

input[type=text], input[type=number] {
  padding: 5px;
  min-width: 100px;
  color: black;
  font-size: 20px;
  text-align: center;
  border: 2px solid rgb(204, 204, 204);
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  padding: 10px;
  color: white;
  border: none;
  border-radius: 3px;
  background-color: rgb(34, 34, 34);
  -webkit-appearance: none;
}
button:hover {
  background-color: rgb(50, 50, 50);
}
button:active {
  position: relative;
  top: 2px;
}
button.image {
  margin: 3px;
  color: black;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 3px 2px rgb(197, 197, 197);
}
button.image:hover {
  background-color: rgb(236, 236, 236);
}

.fullSize, .puzzleQuestion__screen__image, .openQuestion__screen, .multipleChoice, .questionHead__image, .titleSlide img, .titleSlide, .screen__scoreboard, .screen__lobby, .player__score, .dashboard, .center, .screen__lobby__name {
  width: 100%;
  height: 100%;
}

.center, .titleSlide, .screen__lobby__name, .dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 9px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #858585;
  border: 2px solid rgba(255, 255, 255, 0);
  border-width: 8px 3px 8px 3px;
}
::-webkit-scrollbar-thumb:hover {
  border-left-width: 0;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

.spinner {
  display: block;
  width: 30vw;
  opacity: 0;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1.5vh solid transparent;
  border-top-color: #AAE578;
  animation: fadeIn 0.5s linear 0.5s 1 normal forwards, fullRotation 2s linear 0.5s infinite normal forwards;
}

.inputBox, .dropdownSelect {
  display: grid;
  grid-template-rows: 1fr 50px 50px;
  padding: 10px;
  width: 250px;
  height: 150px;
  border-radius: 3px;
  background-color: white;
}
.inputBox label, .dropdownSelect label {
  color: black;
  text-align: center;
  font-size: 20px;
}
.inputBox button, .dropdownSelect button {
  margin-top: 10px;
  font-size: 16px;
}

.dropdownSelect {
  padding: 20px;
  width: auto;
  height: 160px;
}
.dropdownSelect > select {
  padding: 8px;
  border: 2px solid rgb(214, 214, 214);
  border-radius: 3px;
  background: white;
}

.countdown {
  position: relative;
  width: 150px;
  height: 150px;
}
.countdown__stroke {
  fill: none;
  stroke: none;
}
.countdown__label {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 40px;
}
.countdown__path-remaining {
  stroke-width: 8px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  stroke: #AAE578;
}
.countdown__svg {
  transform: scaleX(-1);
}
.countdown--sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  height: 100vh;
}
.countdown--sidebar .countdown__label {
  align-self: center;
  justify-self: center;
  width: inherit;
  height: inherit;
  font-size: 32px;
  transform: rotate(-90deg);
}
.countdown--sidebar .countdown__label:after {
  content: "";
  position: absolute;
  width: 85px;
  height: 40px;
  z-index: -1;
  border-radius: 0 0 10px 10px;
  background-color: white;
}
.countdown__sidebar {
  display: block;
  width: 10px;
  height: 0;
  animation-name: shrimp;
  animation-timing-function: linear;
  border-radius: 15px;
  background-color: white;
}

.errorBoundary {
  padding: 20px;
  border-radius: 20px;
  background-color: white;
}
.errorBoundary__buttons {
  display: flex;
  gap: 20px;
}
.errorBoundary__error {
  padding: 15px;
  color: red;
  border-radius: 15px;
  border: 2px solid rgb(255, 0, 0);
  background-color: rgb(255, 226, 226);
}

.dashboard__container {
  position: relative;
  margin: 120px;
  padding: 50px;
  min-width: 1200px;
  max-width: 1200px;
  height: 80vh;
  min-height: 750px;
  border-radius: 25px;
  background-color: #FFFFFF;
  box-shadow: 8px 8px 40px #D1DDC8;
}
.dashboard__topBar {
  display: flex;
  flex-direction: row;
  height: 75px;
}
.dashboard__topBar > #game-state {
  margin: auto 0px auto auto;
  font-family: "NTR";
  font-size: 28px;
  line-height: 59px;
  text-transform: capitalize;
  color: #000000;
}
.dashboard__topBar__slideIndex {
  margin: auto 0px auto 27px;
  padding: 12px 22px;
  border-radius: 50px;
  background: #AAE578;
}
.dashboard__topBar__slideIndex p {
  margin: 0;
  font-family: "Inter";
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
}
.dashboard__sideNav {
  position: absolute;
  top: calc(50% - 119px);
  left: -41px;
  display: flex;
  justify-content: center;
  padding-top: 0px;
  width: 82px;
  background: #AAE578;
  border-radius: 50px;
}
.dashboard__sideNav ul {
  padding: 0;
  list-style: none;
}
.dashboard__sideNav li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
.dashboard__sideNav li img {
  width: 26px;
  height: 26px;
}
.dashboard__sideNav li.active {
  border-radius: 50%;
  background-color: #77a154;
}
.dashboard__sideNav li:hover {
  border-radius: 50%;
  background-color: #99c475;
}
.dashboard__main {
  display: grid;
  grid-template-columns: 1.5fr 1fr 210px;
  grid-template-rows: 206px 150px;
  grid-gap: 20px;
  margin: 35px 0 0 50px;
  height: 370px;
}
.dashboard__main__info, .dashboard__main__nAnswered, .dashboard__main__time, .dashboard__main__description, .dashboard__main__answers, .dashboard__main__mediaControls {
  display: block;
  padding: 30px;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(170, 229, 120, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.dashboard__main__info p, .dashboard__main__nAnswered p, .dashboard__main__time p, .dashboard__main__description p, .dashboard__main__answers p, .dashboard__main__mediaControls p {
  margin: 0;
  font-family: "Inter";
  font-size: 24px;
}
.dashboard__main__mediaControls {
  padding: 15px;
}
.dashboard__main__answers {
  padding: 20px;
}
.dashboard__main__answers > div {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #E4E4E4;
  overflow-y: auto;
}
.dashboard__main__answers__answer {
  padding: 5px;
  font-size: 18px !important;
  background-color: #ed9994;
  border-bottom: 2px solid white;
  word-break: break-all;
}
.dashboard__main__answers__answer--correct {
  background-color: #94edb2;
}
.dashboard__main__answers__answer button {
  padding: 2px 5px;
  float: right;
}
.dashboard__main__description {
  overflow-y: scroll;
}
.dashboard__main__description p {
  font-size: 18px;
  word-break: normal;
}
.dashboard__main__nAnswered {
  padding: 20px;
}
.dashboard__main__nAnswered p {
  font-size: 21px;
}
.dashboard__main__nAnswered > div {
  width: 100%;
  height: 125px;
}
.dashboard__main__nAnswered > div ul {
  padding: 12px;
  height: 100%;
  border-radius: 10px;
  background-color: #E4E4E4;
  list-style: none;
  overflow-y: auto;
}
.dashboard__main__nAnswered > div ul li {
  margin-bottom: 10px;
  line-height: 18px;
  word-break: break-word;
}
.dashboard__functions {
  position: relative;
  margin-left: 50px;
}
.dashboard__functions > h2 {
  margin-bottom: 8px;
  color: #000000;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  font-family: "NTR";
}
.dashboard__functions__button {
  display: block;
  background-color: #FFFFFF;
}
.dashboard__functions__button:hover {
  background-color: #f8f8f8;
}
.dashboard__functions__button:disabled {
  top: 0;
  background-color: #e7e7e7;
  box-shadow: 0px 0px 57.5px rgba(170, 229, 120, 0.1), 2px 2px 8px rgba(180, 180, 180, 0.2) !important;
}
.dashboard__functions__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 115px);
  grid-gap: 20px;
}
.dashboard__functions__grid .dashboard__functions__button {
  width: 115px;
  height: 115px;
  border-radius: 28px;
  box-shadow: 0px 0px 57.5px rgba(170, 229, 120, 0.1), 2.875px 2.875px 11.5px rgba(0, 0, 0, 0.2);
}
.dashboard__functions__extra {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  top: 38px;
  right: 0;
}
.dashboard__functions__extra .dashboard__functions__button {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  box-shadow: 0px 0px 33.4951px rgba(170, 229, 120, 0.1), 1.67476px 1.67476px 6.69903px rgba(0, 0, 0, 0.2);
}
.dashboard__players {
  display: block;
  padding: 30px;
  margin: 35px 0 0 50px;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(170, 229, 120, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.2);
  height: calc(100% - 110px);
  overflow-y: auto;
}
.dashboard__players__info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content fit-content;
  padding: 15px;
}
.dashboard__players__info h2 {
  grid-row: 1/2;
  padding-bottom: 10px;
}
.dashboard__players__info p {
  grid-row: 2/3;
  font-size: 18px;
}
.dashboard__players__info p:nth-of-type(2) {
  margin: auto;
}
.dashboard__players__info p:nth-of-type(3) {
  margin-left: auto;
}
.dashboard__players__backButton {
  grid-column: 3/4;
  grid-row: 1/2;
  position: relative;
  top: -5px;
  margin-left: auto;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: none;
}
.dashboard__players__backButton:hover {
  top: -5px;
  background-color: #dddddd;
}
.dashboard__players__backButton:hover svg path {
  fill: #838383;
}
.dashboard__players__answers {
  padding: 7px 15px;
}
.dashboard__players__answers h2 {
  padding-bottom: 10px;
}
.dashboard__players__answer {
  padding: 10px 10px 10px 20px;
  border-bottom: 2px solid #d4d4d4 !important;
}
.dashboard__players__answer:first-of-type {
  border-top: 2px solid #d4d4d4 !important;
}
.dashboard__players__answer b {
  margin-left: auto;
}
.dashboard__players__answer p:nth-of-type(2), .dashboard__players__answer p:nth-of-type(3) {
  margin-left: auto;
}
.dashboard__players__answer label {
  margin-right: 5px;
}
.dashboard__players__answer div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7px;
}
.dashboard__players__answer input {
  margin-right: 20px;
}
.dashboard__players__answer input[type=number] {
  width: 60px;
  font-size: 18px;
}
.dashboard__players__answer button {
  margin-left: 20px;
}
.dashboard__players__player {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  color: black;
  font-size: 18px;
  text-align: left;
  border-bottom: 2px solid #d4d4d4 !important;
  background-color: white;
}
.dashboard__players__player:first-of-type {
  border-top: 2px solid #d4d4d4 !important;
}
.dashboard__players__player:last-of-type {
  border-bottom: none;
}
.dashboard__players__player p:last-of-type {
  margin-left: auto;
  margin-right: 60px;
}
.dashboard__players__player button {
  color: #2b2b2b;
  font-size: 18px;
  background-color: white;
}
.dashboard__players__player button:hover {
  background-color: #dddddd;
}
.dashboard__settings {
  margin: 35px 0 0 50px;
}
.dashboard__settings__setting {
  display: block;
  padding: 30px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(170, 229, 120, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.dashboard__settings__setting label {
  font-size: 22px;
}
.dashboard__settings__setting div {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.dashboard__settings__setting input {
  margin-right: 20px;
  padding: 10px;
  text-align: left;
}
.dashboard__settings__setting button {
  margin-left: auto;
  width: 75px;
  color: white;
  font-size: 18px;
  background-color: #AAE578;
}
.dashboard__settings__setting button:hover {
  background-color: #90bb6b;
}
.dashboard__settings__setting button:active {
  top: 1px;
}
.dashboard__scoreboard {
  margin: 35px 0 0 50px;
  height: calc(100% - 110px);
}
.dashboard__scoreboard > div {
  height: calc(100% - 45px);
  border: 2px solid #d4d4d4;
  overflow-y: auto;
}

.scoreboard {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  overflow-y: auto;
}
.scoreboard__item {
  display: grid;
  position: relative;
  grid-template-columns: 40px 1fr 100px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #5498FF;
  transition: transform 1s ease-in-out;
}
.scoreboard__item p {
  margin: 0;
  transition: opacity 0.2s ease-in-out;
  color: white;
  font-size: 20px;
}
.scoreboard__item p:last-of-type {
  text-align: right;
}

.alert {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90vw;
  min-height: 50px;
  border-radius: 4px;
  background-color: #FF5252;
  animation: fadeIn 0.5s ease;
}
.alert svg {
  margin: auto 0;
  min-width: 50px;
}
.alert p {
  margin: auto 20px auto 10px;
  padding: 7px 0;
  color: white;
}

.player__header {
  width: 100%;
  height: 40px;
  padding: 10px;
  background-color: transparent;
}
.player__header p {
  margin: 0;
  color: white;
  font-size: 20px;
}
.player__result {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.player__result svg {
  margin-top: 5%;
  height: 50%;
}
.player__result svg path {
  stroke-dasharray: 1100;
  stroke-dashoffset: 1100;
  animation: circleSpin-1 500ms ease-in-out forwards;
}
.player__result > div {
  margin-top: 5%;
  margin-bottom: 10%;
}
.player__result--wrong > div, .player__result--none > div {
  margin-top: 8%;
}
.player__result p {
  margin: 0;
  color: white;
  font-size: 40px;
  text-align: center;
}
.player__result__points-gained {
  margin-bottom: 5% !important;
  font-size: 35px !important;
  opacity: 0;
  animation: fadeIn 0.8s ease 0.2s 1 normal forwards;
}
.player__score {
  position: relative;
  display: grid;
  grid-template-rows: 5fr 12fr 4fr 6fr;
  justify-content: center;
  grid-row: 2/4;
  overflow: hidden;
}
.player__score p {
  margin: 0;
  color: white;
  text-align: center;
}
.player__score p:nth-of-type(1) {
  align-self: center;
  font-size: 45px;
  line-height: 53px;
  animation: bounceDown 500ms ease-in-out forwards;
}
.player__score p:nth-of-type(2) {
  grid-row: 2/3;
  opacity: 0;
  align-self: center;
  font-size: clamp(150px, 40vw, 200px);
  animation: fadeIn 450ms 650ms ease-out forwards;
}
.player__score p:nth-of-type(3) {
  opacity: 0;
  margin-top: auto;
  font-size: 35px;
  line-height: 41px;
  animation: moveDown 300ms 800ms ease-out forwards, fadeIn 300ms 800ms linear forwards;
}
.player__score p:nth-of-type(4) {
  opacity: 0;
  font-size: 70px;
  line-height: 82px;
  animation: fadeIn 200ms 900ms ease-out forwards;
}
.player__score svg {
  position: absolute;
  grid-row: 2/3;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: circleSpin-1 50ms 350ms ease-in-out forwards, fullRotation 400ms 400ms linear forwards, circleSpin-2 300ms 800ms ease-in-out forwards;
}

.screen__lobby {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-template-rows: repeat(auto-fill, 100px);
  grid-gap: 20px;
  justify-content: center;
  padding-inline: 20px;
}
.screen__lobby__name {
  overflow: hidden;
}
.screen__lobby__name p {
  padding: 10px;
  animation: popIn 0.5s;
  color: #000000;
  font-size: 20px;
  text-align: center;
  word-break: break-word;
  border-radius: 10px;
  background: rgb(255, 255, 255);
}
.screen__lobby__center {
  grid-column: 5/8;
  grid-row: 3/7;
  justify-self: center;
  align-self: center;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
}
.screen__lobby__center p {
  padding: 10px;
  margin: 5px;
  font-size: 25px;
}
.screen__lobby__center img {
  animation: popIn 0.5s;
  margin-top: 10px;
  border-radius: 10px;
  border: 4px solid #ebebeb;
}
.screen__scoreboard {
  padding: 50px 25%;
  overflow-y: auto;
}
.screen__scoreboard .scoreboard {
  background: none;
}
.screen__scoreboard .scoreboard__item {
  grid-template-columns: 60px 1fr 100px;
  margin-bottom: 30px;
  background-color: white;
}
.screen__scoreboard .scoreboard__item p {
  color: black;
  font-size: 30px;
}

.titleSlide {
  position: relative;
}
.titleSlide h1 {
  display: block;
  padding: 30px;
  color: #ffffff;
  font-size: clamp(30px, 4vw, 50px);
  line-height: clamp(25px, 3vw, 40px);
  font-weight: 400;
  font-family: "NTR";
  border-radius: 20px;
  background-color: #FF5252;
  z-index: 1;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.2);
}
.titleSlide img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
}

.questionHead {
  position: relative;
  padding: 20px;
}
.questionHead__result {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
}
.questionHead__result h2 {
  margin: 0;
  color: white;
  font-size: 75px;
  text-align: center;
}
.questionHead__result__correct {
  grid-column: 2/3;
}
.questionHead__result__incorrect {
  grid-column: 4/5;
}
.questionHead__question h1 {
  position: absolute;
  padding: 12px 25px;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 15px;
  background-color: white;
}
.questionHead__image {
  max-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionHead__image img {
  max-width: 100%;
  max-height: 100%;
}
.questionHead__image--large {
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 100vw;
  height: 100vh;
  max-height: initial;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.37);
}
.questionHead__image--large img {
  height: 100%;
}

.multipleChoice {
  display: grid;
  grid-template-rows: repeat(2, calc(50% - 50px));
  gap: 25px;
  padding: 80px 100px 50px 100px;
}
.multipleChoice__buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33.3333333333%, 1fr));
  gap: 20px;
  grid-row: 2/4;
  padding: 20px;
  width: 100%;
  height: 100%;
}
.multipleChoice__button {
  font-size: clamp(10px, 7vw, 30px);
  border: 8px solid white;
  border-radius: 20px;
}
.multipleChoice__button:nth-of-type(1) {
  background-color: #FF5252;
}
.multipleChoice__button:nth-of-type(2) {
  background-color: #70DADA;
}
.multipleChoice__button:nth-of-type(3) {
  background-color: #74F269;
}
.multipleChoice__button:nth-of-type(4) {
  background-color: #5498FF;
}
.multipleChoice__button:last-child:nth-child(3) {
  grid-column: 1/3;
}
.multipleChoice__button:hover {
  filter: brightness(90%);
  -webkit-filter: brightness(90%);
}
.multipleChoice__button:disabled {
  transition: opacity 0.4s, transform 0.4s;
  opacity: 0.5;
  filter: grayscale(100%);
  transform: scale(0.8);
}

.correctOrder {
  display: grid;
  grid-template-rows: repeat(2, 50%);
  padding: 40px;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
}
.correctOrder__screen {
  padding: 80px 100px 50px 100px;
}
.correctOrder__screen .correctOrder__container {
  min-width: 350px;
  width: -moz-fit-content;
  width: fit-content;
}
.correctOrder__container {
  justify-self: center;
  align-self: center;
  padding: 20px;
  width: 100%;
}
.correctOrder .sortable {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 5px 0 5px;
  padding: 10px;
  width: 100%;
  height: -moz-min-content;
  height: min-content;
  font-size: 18px;
  line-height: 20px;
  border-radius: 3px;
  border: 4px solid #ffffff;
  background-color: #ffffff;
}
.correctOrder .sortable-ghost {
  border-left-color: #da00da;
}
.correctOrder .sortable p {
  margin: 0;
}
.correctOrder .sortable img {
  width: 20px;
}
.correctOrder button {
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  width: 150px;
  height: 50px;
  font-size: 25px;
}

.openQuestion {
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
}
.openQuestion__screen {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 80px;
  align-items: initial;
}
.openQuestion__answers p {
  color: white;
  text-align: center;
  font-size: xx-large;
}
.openQuestion > h2 {
  margin-bottom: 40px;
  font-weight: 400;
  text-align: center;
}
.openQuestion > input {
  text-align: left;
}
.openQuestion > button {
  justify-self: center;
  align-self: center;
  margin-top: 40px;
  width: 150px;
  height: 50px;
  font-size: 25px;
}

.puzzleQuestion {
  display: grid;
  grid-template-rows: fit-content 1fr;
  padding: 40px;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
}
.puzzleQuestion__screen {
  padding: 80px 100px 50px 100px;
  grid-template-rows: min-content 1fr;
}
.puzzleQuestion__screen__image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.puzzleQuestion__puzzle {
  display: grid;
  justify-self: center;
  align-self: center;
}
.puzzleQuestion__piece__outline {
  outline: 2px solid white;
  z-index: 1;
}
.puzzleQuestion button {
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  width: 150px;
  height: 50px;
  font-size: 25px;
}